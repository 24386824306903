export default defineNuxtRouteMiddleware(() => {
    if (import.meta.server) { return }

    const { loggedIn } = useAuth()
    if (loggedIn) {
        return navigateTo('/')
    }else {
        window.location.href = `/api/kinde/login`
    }
})
